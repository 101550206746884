import moment from "moment";
import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Badge, Col, Row, Container } from "react-bootstrap";
import {
  IoCloseCircleOutline,
  IoCloseOutline,
  IoCreateOutline,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import {
  deleteWithdrawal,
  editWithdrawal,
  getWithdrawals,
} from "../../services/withdrawal";

const Withdrawals = () => {
  const inputRef = createRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [categories, setWithdrawals] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const withdrawalColumns = [
    {
      name: "Пользователь",
      cell: (row) => (
        <Link to={`/user/${row?.user?.id}`}>{row?.user?.nickname}</Link>
      ),
    },
    {
      name: "Сумма",
      selector: "price",
    },
    {
      name: "Время и дата",
      cell: (row) => (
        <>
          <span className="me-1">
            {moment(row.createdAt).format("DD.MM.YYYY")}
          </span>
          <span className="fw-7">{moment(row.createdAt).format("kk:mm")}</span>
        </>
      ),
    },
    {
      name: "Статус",
      selector: "status",
      cell: (row) =>
        !row.status || row.status === "process" ? (
          <Badge bg="secondary">В ожидании</Badge>
        ) : row.status === "ok" ? (
          <Badge bg="success">Выполнено</Badge>
        ) : (
          row.status === "canceled" && <Badge bg="danger">Отменено</Badge>
        ),
    },
    {
      name: "Номер карты",
      width: "120px",
      selector: "cartNumber",
      align: "center",
      cell: (row) => (
        <div className="d-flex align-items-center justify-content-center">
          <span>{row?.cardNumber}</span>
        </div>
      ),
    },
  ];

  const header = useMemo(() => {
    return (
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div>
          <h5 className="fw-7">
            {selected.length > 0
              ? `Выбрано ${selected.length}`
              : "Выводы средств"}
          </h5>
        </div>
        <div className="search-box mx-5">
          <Input
            // readOnly={false}
            ref={inputRef}
            placeholder="Найти"
            className="w-100"
            onChange={(e) => {
              searchParams.set("text", e);
              setSearchParams(searchParams);
            }}
            defaultValue={searchParams.get("text")}
            onKeyDown={(e) => e === "Enter" && onSearch()}
          />
        </div>
      </div>
    );
  }, [selected, searchParams, modalDelete]);

  const getData = useCallback(async () => {
    getWithdrawals(searchParams)
      .then((res) =>
        setWithdrawals((prev) => ({
          ...prev,
          loading: false,
          ...res,
        }))
      )
      .finally(() => setWithdrawals((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onEditStatus = useCallback((data) => {
    editWithdrawal(data)
      .then(() => {
        getData();
        NotificationManager.success("Запрос обновлен");
      })
      .catch((err) => {
        NotificationManager.error("Ошибка при запросе");
      });
  }, []);

  const onDelete = useCallback((id) => {
    deleteWithdrawal(id)
      .then(() => {
        getData();
        NotificationManager.success("Запрос успешно удален");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, []);

  const onDeleteSelected = useCallback(() => {
    deleteWithdrawal(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success("Выбранные запросы успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при запросе"
        )
      );
  }, [selected]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams]);

  useLayoutEffect(() => {
    setSearchParams({ type: 'pay' })
    getData();
  }, [searchParams.get("page")]);

  if (categories.loading) {
    return <Loader full />;
  }

  return (
    <Container fluid={true}>
      <Meta title="Выводы средств" />
      <section className="box">
        <DataTable
          columns={withdrawalColumns}
          onChange={(items) => setSelected(items)}
          data={categories.items}
          header={header}
          selectable
          pagination={categories.pagination}
        />
      </section>
      <CustomModal
        title={
          selected.length > 0
            ? `Удаление ${selected.length} элементов`
            : "Удаление элемента"
        }
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() =>
                selected.length > 0
                  ? onDeleteSelected()
                  : modalDelete.id && onDelete(modalDelete.id)
              }
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить?
      </CustomModal>
    </Container>
  );
};

export default Withdrawals;
